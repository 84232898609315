.question-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-list {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin: 5% 10%;
}

.question-info {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h2 {
    color: #4c9ddb;

    &:first-child {
      span {
        color: red;
      }
    }

    &:last-child {
      span {
        color: #35b458;
      }
    }
  }
}

.tries,
.selected-points {
  font-weight: bold;
}

.question-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-bottom: 20px;
}

.box {
  width: 200px;
  height: 200px;
  font-size: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed white;
  color: white;
  background-color: red;

  &.box-1 {
    background-color: orange;
  }

  &.box-2 {
    background-color: blueviolet;
  }

  &.box-3 {
    background-color: lightseagreen;
  }

  &.box-4 {
    background-color: tomato;
  }

  &.box-5 {
    background-color: magenta;
  }

  &.special-question {
    background-color: red;
  }


  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.open-box {
    opacity: 0.5;
  }

}

.question {
  transition: background-color 0.5s ease;
}

.question.selected {
  background-color: lightblue;
  transition: background-color 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.button {
  width: 30%;
  font-size: 24px;
  background-color: #176f97;
  color: white;
  padding: 15px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #809fc0;
  }
}