.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  height: 100%;
  box-sizing: border-box;
  width: 300px;
  margin: 0 auto;

  .user-info {
    border: 2px solid rgb(165, 162, 162);
    border-radius: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    label {
      margin: 5px 0;
    }

    input {
      width: 250px;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 10px;
  }
}
