.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: auto;
  height: auto;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: gray;
  color: white;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgb(204, 192, 192);
  }
}