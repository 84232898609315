* {
  border: 0;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.main-app {
  flex-grow: 1;
  padding: 0.5rem;
  overflow: auto;
}

.gray {
  color: gray;
}