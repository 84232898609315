.question-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  padding: 20px;
  border: 2px solid #d2cfcf;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(178, 177, 178, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  .question-content {
    display: flex;
    flex-direction: column;
  }

  .points-section {
    text-align: right;

    p {
      margin: 0;
      padding: 5px 0;
      font-size: 18px;
      color: #240fda;

      span {
        color: #da0f0f;
        font-weight: bold;
      }
    }
  }

  .answer-section {
    margin-top: 15px;

    textarea {
      width: 100%;
      min-height: 150px;
      padding: 10px;
      border: 2px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      resize: vertical;
      font-size: 14px;
      line-height: 1.4;
      white-space: normal;

      &:focus {
        outline: none;
        border-color: #979898;
      }
    }
  }

  .modal-actions {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }


  button {
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:focus {
      outline: none;
    }
  }

  .submit-button {
    background-color: #0238a3;
    color: #fff;

    &:hover {
      background-color: #625ee1;
    }
  }

  .edit-button {
    background-color: #0e8d64;
    color: #fff;

    &:hover {
      background-color: #60d974;
    }
  }

  .close-button {
    background-color: #f44336;
    color: #fff;

    &:hover {
      background-color: #b86870;
    }
  }
}