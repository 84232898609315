.examinees-card-wrapper {
    width: 90%;
    margin: auto;
    margin-top: 100px;
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    position: relative;

    .examinees-card {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 2px solid #d2cfcf;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(178, 177, 178, 0.1);
        cursor: pointer;
    }
}

.exam-review-modal {
    width: 80%;
    max-height: 77vh;
    position: fixed;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 20px;
    margin-top: 100px;
    border: 2px solid #d2cfcf;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(178, 177, 178, 0.1);
    transition: top 0.3s ease-in-out;
    overflow-y: auto;

    &.open {
        top: 50px;
    }

    .modal-content {
        padding: 30px;
    }

    .questions-list {
        padding: 20px 0 0 0;
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));

        .question-item {
            min-height: 300px;
            padding: 10px;
            border: 2px solid #d2cfcf;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(178, 177, 178, 0.1);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            .status-buttons {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 5px 10px;

                    &.correct-button.active {
                        background-color: #60d974;
                        color: white;
                    }

                    &.wrong-button.active {
                        background-color: #f44336;
                        color: white;
                    }
                }
            }
        }
    }

    button {
        height: 30px;
        float: right;
        cursor: pointer;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin: 10px 0 0 5px;
        padding: 4px;
    }
}

.closed {
    display: none;
}

.question-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.total-points {
    padding: 18px;
}