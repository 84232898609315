.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(108, 106, 106, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-modal {
  background-color: #fff;
  border: 1px solid #7a7676;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal-content {
  margin-bottom: 15px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.confirm-button:hover,
.cancel-button:hover {
  background-color: #0238a3;
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #c20417;
}
