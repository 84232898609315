.stage-cube-cont {
  .cube-spinner {
    position: relative;
    width: 50px;
    height: 50px;
    transform-style: preserve-3d;
    transform-origin: center center;
    animation: spincube 12s linear infinite;

    div {
      position: absolute;
      width: 50px;
      height: 50px;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(1) {
        transform: rotateY(0deg) translateZ(25px);
      }
      &:nth-child(2) {
        transform: rotateY(90deg) translateZ(25px);
      }
      &:nth-child(3) {
        transform: rotateY(180deg) translateZ(25px);
      }
      &:nth-child(4) {
        transform: rotateY(-90deg) translateZ(25px);
      }
      &:nth-child(5) {
        transform: rotateX(90deg) translateZ(25px);
      }
      &:nth-child(6) {
        transform: rotateX(-90deg) translateZ(25px);
      }

      svg {
        font-size: 40px;
      }
    }
  }

  @keyframes spincube {
    from,
    to {
      transform: rotateY(0deg) rotateX(0deg);
    }
    16% {
      transform: rotateY(-90deg) rotateX(0deg);
    }
    33% {
      transform: rotateY(-180deg) rotateX(0deg);
    }
    50% {
      transform: rotateY(90deg) rotateX(0deg);
    }
    66% {
      transform: rotateY(90deg) rotateX(-90deg);
    }
    83% {
      transform: rotateY(90deg) rotateX(90deg);
    }
  }
}
