.exam-info-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.exam-info-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.exam-info-content {
  margin-bottom: 20px;
}

.exam-info-button {
  display: flex;
  justify-content: center;
}
