.shareable-link {
  margin-top: 10px;

  p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  a {
    display: inline-block;
    padding: 8px;
    background-color: #f0f0f0;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #dcdcdc;
    }
  }
}
