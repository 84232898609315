.exam-finished-container {
  text-align: center;
  height: 70vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;

  .exam-finished {
    border: 2px solid gray;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
  }

  h1 {
    font-size: 36px;
    margin-bottom: 15px;
  }
 
  h2 {
    color: #b95353;
  }
}
