.user-header {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: rgb(250, 247, 247);
    background: linear-gradient(to bottom, #dddddd, #fcfffc);
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    h1 {
        font-family: "Helvetica Neue", sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        color: #6ea3e9;
        text-align: center;
        letter-spacing: 2px;
        margin: 0;
        position: relative;
        padding-left: 1.5rem;
    }

}