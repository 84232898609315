.timer-container {
  text-align: center;
  display: flex;
  justify-content: center;

  .timer {
    font-size: 2rem;
    font-weight: bold;
    color: #898b8c;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    padding: 10px 20px;
    border-radius: 8px;
    display: inline-block;

    &.timer-expired {
      color: red;
    }
  }
}
