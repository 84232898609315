.department-link {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f8f8;

  .link {
    color: rgb(164, 162, 162);
    text-decoration: none;
    font-weight: bold;
    margin-right: 10px;
  }
}
